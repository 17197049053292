export const conformity = {
	'0': 0,
	'1/2': 0.5,
	'1': 1,
	'2': 2,
	'3': 3,
	'5': 5,
	'8': 8,
	'13': 13,
	'20': 20,
	'40': 40,
	'100': 100,
	'?': 1000,
	'♾': 10000,
};

export const conformityRevers = {
	0: '0',
	0.5: '1/2',
	1: '1',
	2: '2',
	3: '3',
	5: '5',
	8: '8',
	13: '13',
	20: '20',
	40: '40',
    100: '100',
    1000: '?',
    10000:'♾'
};
